
.homebannersection {
	background-image: url(/src/img/banner.jpg);
	min-height: 800px;
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	align-items: center;
	padding: 0 20px;
	position: relative;
	z-index: 1;
}
/* new css*/ 
.home .productsrow {
    padding-bottom: 220px;
}
.svgic.tests path {
    fill: #fff;
}
.saveic.bgblck {
    background: #030200;
	margin-bottom: -2px;
    margin-top: -3px;
}
.svgic path {
    fill: #f2f2f2;
}
.svgic {
    margin-top: -230px;
    z-index: 99;
    position: relative;
    margin-bottom: -80px;
}
.saveic {
    height: 170px;
    background: #f2f2f2;
}
.home .trackrecord {
    background: #192151;
}
.home  .trackrecordinner h2 {
    color: #fff;
}

.home .trackcolumn.iconcolumn {
    grid-gap: 20px;
    display: grid;
    flex-wrap: wrap;
    gap: 30px;
    grid-template-columns: repeat(2, 1fr);
}
.home .trackninner {
    display: flex;
    gap: 10px;
    align-items: center;
    border-radius: 0px 43px 0px 0px;
    padding: 20px 20px;
}
.home .trackcontent {
	width: 70%;
}
.home .trackninner p {
    text-align: left;
}
.home .trimg {
    width: 25%;
}
.home.trackcontent {
    width: 50%;
}
.home .buttonsec {
    width: 35%;
    text-align: right;
}
.home .buttonsec button.btn.btn1.mtop {
    margin-top: 0px;
    padding: 10px 18px !important;
    background: #091D3E;
    border-radius: 3px;
    color: #fff;
}
.home .bottobtn .btn.btn1 {
    color: #fff;
    font-size: 16px;
    font-weight: 600;
}
/* new css close */
.iconcontainer {
	max-width: 1450px;
	margin: auto;
}
.bannercontent h1 {
  font-size: 84px;
  text-transform: uppercase;
  color: #fff;
  line-height: 1;
}
.bannercontent h2 {
  font-size: 38px;
  color: #fff;
  margin-top: 0;
  text-transform: uppercase;
}
.bannercontent p {
	font-size: 20px;
	color: #fff;
	line-height: 1.4;
}
.iconcontainer {
	margin-top: 30px;
}
.btn {
	padding: 10px 22px !important;
	padding-bottom: 7px !important;
}
.btn {
  background: transparent;
  color: #fff;
  border: 1px solid;
}
.btn:hover {
	color: #192151;
	background: #48c0b670;
	border-color: transparent;
}
.bannerinner .btn:hover {
	background: #48c0b6;
	color: #fff;
}
.bannerinner {
    max-width: 1450px;
    margin: 0 auto;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 130px;
}
.trackninner img {
	margin-left: auto;
	margin-right: auto;
}
.inbox img {
	margin-left: auto;
	margin-right: auto;
}
.footerinnercolumn1 img {
	/*margin-left: auto;*/
	margin-right: auto;
}
.innerbox01 img {
	margin-left: auto;
	margin-right: auto;
}
.bannercontent {
  max-width: 672px;
}
.missionsection {
	padding: 80px 20px;
	background: #f2f2f2;
	position: relative;
}
.missioncontent h3 {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  color: #49c0b6;
  font-weight: 700;
  margin-bottom: 20px;
}
.missioncontent h2 {
  font-size: 64px;
  text-align: center;
  color: #192151;
  line-height: 1.1;
}
.missioncontent {
  max-width: 900px;
  margin: auto;
}

.iconcontainer {
  display: flex;
  gap: 20px;
  background-color: transparent;
  padding: 0;
  margin-top: 30px;
}

.iconcolumn {
	display: flex;
	flex: 1 1;
	gap: 20px;
	flex-wrap: wrap;
}
.iconinner p {
  font-size: 20px;
  margin-bottom: 0;
  line-height: 1.4;
  font-weight: 500;
  color: #474747;
}
.iconinner h2 {
  font-size: 30px;
  color: #192151;
  font-weight: 700;
}

.iconinner {
	display: flex;
	align-items: flex-start;
	flex: 1 1;
	background-color: #ffffff;
	padding: 40px 20px;
	box-sizing: border-box;
	box-shadow: 0 0 22px rgba(0,0,0,.12);
	position: relative;
	z-index: 1;
}

.iconinner .image {
  max-width: 100px;
  height: auto;
  margin-right: 20px;
}

.iconinner .content {
  flex: 1;
}
.maxwidcontainer {
	max-width: 1450px;
	margin: 0 auto;
	position: relative;
}
.trackrecord {
	padding: 80px 20px;
}
.trackrecordinner h2 {
	text-align: center;
	font-size: 45px;
	color: #192151;
	text-transform: uppercase;
}
.trackninner {
	text-align: center;
}
.trackninner p {
	font-size: 19px;
	color: #606060;
	line-height: 1.5;
	margin-bottom: 0;
}
.trackninner img {
	margin-bottom: 10px;
}
.img {
  max-width: 100%;
  height: auto;
  display: block; 
}
.home .trackninner img {
	height: auto;
	object-fit: unset !important;
}
.trackninner img {
	height: 70px;
	object-fit: contain;
}
.trackninner {
	background-color: #ffffff;
	padding: 40px 20px;
	box-sizing: border-box;
	border: 1px solid #192151;
	border-radius: 5px;
}
.bottobtn {
	text-align: center;
	margin-top: 25px;
}
.btn.btn1 {
	color: #192151;
	font-size: 16px;
	font-weight: 600;
}
.missionsection::after {
	content: '';
	background: url(/src/img/before.png);
	width: 335px;
	height: 843px;
	position: absolute;
	right: 0;
	top: -20px;
	z-index: 0;
	opacity: 0.2;
}
.productsrow {
	background: url(/src/img/productsbg.jpg);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top center;
  padding: 50px 20px;
}
.leftpro p {
	font-size: 26px;
	color: #fff;
	font-weight: 500;
	margin-bottom: 0;
}
.leftpro h2 {
	font-size: 70px;
	color: #fff;
	margin-bottom: 10px;
	line-height: 1;
}
.trackcolumn  {
	display: grid;
	grid-template-columns: repeat(4,1fr);
	gap: 20px;
	flex-wrap: wrap;
}
.proinner {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 20px;
}
.proinnerrow {
	display: flex;
	align-content: stretch;
	gap: 0;
	flex-wrap: wrap;
}
.innerbox01 {
	background: #fff;
	padding: 40px 20px;
	text-align: center;
	flex: 1 1;
	border-radius: 3px;
}
.innerbox01 h3 {
	color: #192151;
	font-size: 21px;
	font-weight: 500;
	margin-top: 15px !important;
}
.innerbox01 p {
	color: #5e5f61;
	font-size: 18px;
	font-weight: 500;
}
.btn.btn2 {
	color: #192151;
	font-weight: 500;
	width: 100%;
}
.proinnerrow {
	display: flex;
	align-content: stretch;
	gap: 20px;
	flex-wrap: wrap;
}
.innerbox01 img {
	height: 124px;
	object-fit: contain;
}
.testimonailrow {
	padding: 80px 20px;
}
.testimonailinnerrow h2 {
	text-align: center;
	font-size: 45px;
	font-weight: 600;
	color: #192151;
}
.testimonialinnerrow {
	display: grid;
	grid-template-columns: repeat(2,1fr);
	gap: 20px;
	margin-top: 25px;
}

.textboxcon p {
	font-size: 18px;
	color: #5e5f61;
	font-weight: 500;
	line-height: 1.4;
}
.textboxcon img {
	margin-bottom: 10px;
}
.authoname {
	font-size: 15px !important;
	color: #192151 !important;
	margin-bottom: 0 !important;
}
.authoimg img {
	background: #fff;
	padding: 10px;
	border-radius: 100%;
}
.testibox.textbox1 {
	background: #d1efed;
}
.authoimg {
	text-align: right;
}
.testibox {
	display: grid;
	grid-template-columns: 1fr 130px;
	gap: 15px;
	place-items: center;
	border-radius: 5px;
	padding: 20px;
}
.textbox2 {
	background: #f5f6fa;
	margin-top: 20px;
}
.btn.btn1.mtop {
	margin-top: 20px;
}
.homebottomrow {
	padding: 50px 20px;
}
.fourboxes01 {
	display: grid;
	grid-template-columns: repeat(4,1fr);
	gap: 15px;
}
.inbox h3 {
	font-size: 17px;
	color: #192151;
	text-align: center;
	text-transform: uppercase;
	line-height: 1.4;
	margin-bottom: 0;
}
.inbox {
	text-align: center;
	box-shadow: 0 0 28px rgba(0,0,0,.12);
	padding: 20px;
}
.inbox img {
	margin-bottom: 10px;
}
.homebottomrow {
	background: url(/src/img/shadow.png);
	background-position: top;
	background-size: auto;
	background-repeat: no-repeat;
	padding-top: 100px;
}

@media(min-width:1500px){
	.svgic {
		margin-top: -310px;
}
.testimonailrow {
    position: relative;
    top: -80px;
    z-index: 99;
}
.svgic.tests {
    margin-top: -250px;
}
}
@media(max-width:1400px){
	.testimonailrow {
		position: relative;
		top: -70px;
		z-index: 99;
	}
	.missionsection::after {
		display: none;
	}
}
@media (max-width: 1050px) {
	.svgic {
		margin-top: -190px;
		z-index: 99;
		position: relative;
		margin-bottom: -40px;
	}
	}
@media(max-width:767px){
	.home .productsrow {
		padding-bottom: 160px;
	}
	.testimonailrow {
		position: relative;
		top: -30px;
		z-index: 99;
	}
	.svgic {
        margin-top: -120px;
        z-index: 99;
        position: relative;
        margin-bottom: -40px;
    }
	.saveic {
		height: 90px;
		background: #f2f2f2;
	}
	.bannercontent h1 {
		font-size: 35px;
	}
	.home .trackcolumn.iconcolumn {
		grid-template-columns: repeat(1, 1fr);
	}
	.home .trackninner {
		display: block !important;
		text-align: center !important;
	}
	.home .trackninner div {
		width: 100% !important;
		text-align: center;
	}
	.home .buttonsec {
		margin-top: 20px;
	}
	.home .trackcontent {
		margin-top: 15px !important;
	}
	.home .trackcontent p {
		text-align: center !important;
	}
	.homebannersection {
		min-height: 600px;
		background-position: 58% 50%;
	}
	.homebannersection::before {
		content: '';
		background: #000;
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		opacity: 0.2;
		z-index: -1;
	}
	.trackcolumn {
		display: grid;
		grid-template-columns: repeat(1,1fr);
	}
	.bannercontent h2 {
		font-size: 30px;
	}
	.bannercontent p {
		font-size: 17px;
	}
	.homebannersection {
		min-height: 600px;
	}
	.bannerinner {
		padding-top: 150px;
	}
	.header-logo img {
		max-width: 150px;
	}
	.missionsection {
		padding: 40px 20px;
	}
	.missioncontent h2 {
		font-size: 28px;
	}
	.iconinner p {
		font-size: 17px;
	}
	.iconinner h2 {
		font-size: 25px;
	}
	.iconinner {
		padding: 30px 15px;
	}
	.trackrecord {
		padding: 40px 20px;
	}
	.trackrecordinner h2 {
		font-size: 28px;
	}
	.iconcontainer {
		margin-top: 20px;
	}
	.trackninner p {
		font-size: 17px;
	}
	.proinner {
		grid-template-columns: repeat(1,1fr);
	}
	.leftpro h2 {
		font-size: 28px;
	}
	.leftpro p {
		font-size: 18px;
	}
	.innerbox01 {
		flex: unset !important;

	}
	.productsrow {
		padding: 40px 20px;
	}
	.testimonailrow {
		padding: 40px 20px;
	}
	.testimonailinnerrow h2 {
		font-size: 28px;
	}
	.testimonialinnerrow {
		grid-template-columns: repeat(1,1fr);
		margin-top: 20px;
	}
	.testibox {
		display: flex;
		flex-direction: column-reverse;
	}
	.homebottomrow {
		padding: 40px 20px;
		padding-top: 80px !important;
	}
	.fourboxes01 {
		display: grid;
		grid-template-columns: repeat(1,1fr);
		gap: 15px;
	}
	.inbox img {
		max-width: 60%;
	}
	.inbox h3 {
		font-size: 16px;
	}
}
@media(min-width:767px) and (max-width:1024px){
/* new css */
.home .trackninner {
    display: grid;
    gap: 10px;
    align-items: center;
}
.home .buttonsec {
    width: 100%;
    text-align: left;
}
.home .trackcontent {
    width: 100%;
}
.home .trimg {
    width: 100%;
}
.trimg img {
    margin: 0px 0px 20px 0px;
}

/* new css end */


	.homebannersection {
		min-height: 700px;
	}
	.bannercontent h1 {
		font-size: 60px;
	}
	.bannercontent h2 {
		font-size: 29px;
	}
	.bannerinner {
		padding-top: 150px;
	}
	.bannercontent {
		max-width: 600px;
	}
	.missioncontent h2 {
		font-size: 40px;
	}
	.missionsection {
		padding: 40px 20px;
	}
	.iconinner h2 {
		font-size: 26px;
	}
	.iconinner p {
		font-size: 18px;
	}
	.trackrecord {
		padding: 40px 20px;
	}
	.trackrecordinner h2 {
		text-align: center;
		font-size: 35px;
	}
	.trackcolumn {
		display: grid;
		gap: 20px;
		flex-wrap: wrap;
		grid-template-columns: repeat(2,1fr);
	}
	.proinner {
		display: grid;
		grid-template-columns: repeat(1,1fr);
		gap: 20px;
	}
	.leftpro h2 {
		font-size: 40px;
	}
	.leftpro p {
		font-size: 22px;
	}
	.productsrow {
		padding: 40px 20px;
	}
	.testimonailrow {
		padding: 40px 20px;
	}
	.testimonailinnerrow h2 {
		font-size: 35px;
	}
	.fourboxes01 img {
		height: 85px;
		object-fit: contain;
	}
	.inbox h3 {
		font-size: 16px;
	}
}
@media(min-width:1024px) and (max-width:1200px){
/* new css */
.home .buttonsec button.btn.btn1.mtop {
    margin-top: 0px;
    padding: 10px 11px 6px !important;
    font-size: 13px;
    line-height: 1em !important;
}
.home .trackninner p {
    text-align: left;
    font-size: 16px;
}
/*new css end */

	.missioncontent h2 {
		font-size: 50px;
	}
	.leftpro h2 {
		font-size: 50px;
	}
	.fourboxes01 img {
		height: 150px;
		object-fit: contain;
	}
}



@media (max-width: 500px) {
    .saveic {
        height: 50px;
        background: #f2f2f2;
    }
	.svgic {
        margin-top: -80px;
        z-index: 99;
        position: relative;
        margin-bottom: -10px;
    }
}