  
  .contactrow01 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .casestudies h2 {
	text-align: center;
	font-size: 45px;
	text-transform: uppercase;
	color: #192151;
	margin-bottom: 8px;
	line-height: 1.2;
}
.contactrow01 {
	padding: 40px 20px;
}
.contactrow01 .maxwidcontainer {
	max-width: 800px;
	width: 100%;
	margin: 0 auto;
}
  
  form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Gap between columns and rows */
  }
  
  .half-width {
    flex: 1;
    min-width: calc(50% - 10px); /* Adjust for gap */
  }
  
  .full-width {
    width: 100%;
  }
  
  label {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  input,
  textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 2px;
    font-size: 17px;
    color:#4e4e4e;
    font-family: "Josefin Sans", sans-serif;
  }
  
  textarea {
    height: 100px;
    resize: vertical;
    font-family: "Josefin Sans", sans-serif;
  }
  body .contactrow01 input:focus-visible {
	box-shadow: unset !important;
	border: 1px solid #ddd !important;
	outline: none !important;
}
body .contactrow01 textarea:focus-visible {
	box-shadow: unset !important;
	border: 1px solid #ddd;
	outline: none !important;
}
button {
	padding: 10px 20px;
	border: none;
	border-radius: 2px;
	background-color: #192151;
	color: #fff;
	font-size: 17px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	margin-top: 15px;
}
  
button:hover {
	background-color: #48c0b6;
	color: #192151;
}
  
.statusMessage {
	padding: 10px;
	border-radius: 2px;
	font-size: 16px;
	margin-top: 10px !important;
}
  
  .statusMessage.success {
    background-color: #d4edda;
    color: #155724;
  }
  
  .statusMessage.error {
    background-color: #f8d7da;
    color: #721c24;
  }
  



  .page-id05 .contactrow01 .maxwidcontainer {
    background: #f7f7f7;
    padding: 40px 40px;
    border-radius: 18px;
    box-shadow: 0px 9px 250px 0px #f7f7f7;
}
.page-id05 input, textarea {
    border: 1px solid #ddd;
    border-radius: 6px;
    color: #4e4e4e;
    font-size: 17px;
    height: 30px;
    text-indent: 10px;
    margin-top: 8px;
}
.page-id05 textarea {
    height: 100px;
    resize: vertical;
    padding-top: 15px;
}
.page-id05 button {
    background-color: #48c0b6;
    border: none;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    margin-top: 15px;
    padding: 14px 20px;
    transition: background-color .3s ease;
}
  /* Responsive Styles */
  @media (max-width: 767px) {
    .form-group {
      flex-direction: column;
      gap: 15px; /* Gap between rows in mobile view */
    }
    .contactrow01 h2 {
      padding-top: 15px;
      padding-bottom: 10px;
    }
    .half-width,
    .full-width {
      width: 100%;
    }
    .contactrow01 h2 {
      text-align: center;
      font-size: 28px;
    }
    .page-id05 .contactrow01 .maxwidcontainer {
      padding: 15px !important;
    }
  }
  @media(min-width:768px) and (max-width:1024px){
    .contactrow01 h2 {
      text-align: center;
      font-size: 35px;
    }
  }
  .contactrow01 button:hover {
    background: #48c0b6a6 !important;
    color: #fff !important;
  }