.aboutrow01 {
  padding: 40px 20px;
  position: relative;
}
.aboutimgbox h2 {
  color: #192151;
  font-weight: 500;
  line-height: 1;
  font-size: 49px;
}
.aboutimgbox h3 {
  color: #192151;
  font-weight: 500;
  line-height: 1;
  font-size: 72px;
}
.aboutinner1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  align-items: start;
}
.abcontent p {
  font-size: 23px;
  font-weight: 300;
  color: #474747;
}
.autname {
  font-size: 21px;
  font-weight: 500;
}
.founderimg {
  text-align: center;
}
.aboutrow01::before {
  content: "";
  background: url(/src/img/aboutbefore.png);
  position: absolute;
  width: 227px;
  height: 505px;
  left: 0;
  top: 120px;
  opacity: 0.2;
}
.secondrow {
  padding: 40px 20px;
}
.secinner h2 {
  text-align: center;
  color: #192151;
  font-size: 40px;
  font-weight: 600;
  max-width: 1100px;
  margin: auto;
}
.aboutmapimg {
  text-align: center;
  margin: 30px 0;
}
.threeboxinner01 p {
  font-size: 27px;
  font-weight: 600;
  color: #5c5c5c;
  line-height: 1.3;
}
.threeboxinner01 p span {
  color: #48c0b6;
}
.founderimg img {
	margin-left: auto;
	margin-right: auto;
}
.aboutmapimg img {
	margin-left: auto;
	margin-right: auto;
}
.threeboxes01 {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.threeboxinner01 {
  flex: 1 1;
}
@media (max-width: 767px) {
  .threeboxinner01 {
    flex: unset;
  }
  .aboutinner1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}
@media(max-width:1300px){
  .aboutrow01::before {
    display: none;
  }
}
@media(max-width:767px){
  .aboutimgbox h2 {
    font-size: 26px;
  }
  .aboutimgbox h3 {
    font-size: 32px;
  }
  .abcontent p {
    font-size: 19px;
    font-weight: 300;
    color: #474747;
  }
  .secondrow {
    padding: 20px;
  }
  .secinner h2 {
    font-size: 24px;
  }
  .threeboxinner01 p {
    font-size: 19px;
    margin-bottom: 5px;
  }
}
@media(min-width:768px) and (max-width:1024px){
  .aboutimgbox h2 {
    font-size: 32px;
  }
  .aboutimgbox h3 {
    font-size: 40px;
  }
  .abcontent p {
    font-size: 21px;
  }
  .secinner h2 {
    font-size: 35px;
  }
  .threeboxinner01 p {
    font-size: 22px;
  }
  .secinner h2 {
    max-width: 800px;
    margin: auto;
  }
}
@media(min-width:1025px) and (max-width:1200px){
  .aboutimgbox h2 {
    font-size: 45px;
  }
  .aboutimgbox h3 {
    font-size: 65px;
  }
  .abcontent p {
    font-size: 22px;
  }
  .secinner h2 {
    max-width: 900px;
    margin: auto;
  }
  .threeboxinner01 p {
    font-size: 24px;
  }
}