.header {
  position: sticky;  
  width: 100%;
  z-index: 1000;     
  transition: background-color 0.3s, box-shadow 0.3s; 
}

/* Transparent header for the home/index page */
.header.index {
  background-color: transparent; 
  box-shadow: none;               
}
/* Non-transparent header for other pages */
.header.not-index {
	background-color: rgb(25, 33, 81);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header.scrolled.not-index {
	position: fixed;
}
.header.scrolled .close-menu {
	top: -53px;
}
.header.scrolled.index {
	background-color: rgb(25, 33, 81);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header.index {
	position: fixed;
}
  
  .header.scrolled {
    background-color: rgb(25, 33, 81);
  }
  .menu-items li:last-child a:hover {
    background: #fff;
    color: #2f3662;
  }
  .header-container {
    max-width: 1450px;
    margin: 0 auto;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header-logo img {
    max-width: 220px;
  }

  .header-menu {
    display: flex;
  }
  
  .menu-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .menu-items li {
    margin-left: 40px;
  }
  
  .menu-items a {
    font-size: 16px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .menu-items a:hover {
    color: #48c0b6;
  }
  
  .menu-items li:last-child a {
    background: #48c0b6;
    color: #192151;
    font-size: 17px;
    font-weight: 400;
    padding: 13px 20px;
    border-radius: 2px;
    padding-bottom: 10px;
  }
  
  .hamburger {
    display: none;
    background: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    padding: 0;
  }
  
  .hamburger .bar {
    display: block;
    background-color: #fff;
    height: 2px;
    width: 25px;
    margin: 5px 0;
    transition: background-color 0.3s ease;
  }
  
  .hamburger:hover .bar {
    background-color: #48c0b6;
  }
  
  .close-icon {
    display: none;
    color: #fff;
    font-size: 24px;
  }
  
  .header-menu.open {
    display: block;
    position: absolute;
    top: 60px;
    right: 20px;
    background: #192151;
    width: 100%;
    max-width: 300px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1001;
  }
  
  .header-menu.open .menu-items {
    display: block;
    margin: 0;
    padding: 0;
  }
  
  .header-menu.open .menu-items li {
    margin-left: 0;
    border-bottom: 1px solid #fff;
  }
  
  .header-menu.open .menu-items li:last-child {
    border-bottom: none;
  }
  
  .header-menu.open .menu-items a {
    display: block;
    padding: 15px 20px;
    color: #fff;
    font-size: 18px;
  }
  .close-menu {
    background: #0000;
    border: none;
    color: #fff;
    cursor: pointer;
    display: none;
    font-size: 48.4px;
    height: 30px;
    line-height: 0 !important;
    padding: 0;
    position: absolute;
    right: 20px;
    top: -53px;
  }
  .close-menu {
    margin-top: 0 !important;
  }
  
  @media (max-width: 1024px) {
    .header-menu {
      display: none;
    }
    .hamburger {
      margin-top: 0 !important;
    }
    .header-menu.open {
        display: block;
        max-width: 100%;
        right: 0;
        top: 100%;
    }
  
    .hamburger {
      display: block;
    }
  
    .close-icon {
      display: none;
    }
  
    .close-menu {
      display: block;
    }
    .header-menu.open .menu-items a {
      display: block;
      padding: 15px 20px;
      color: #fff;
      font-size: 15px;
    }
    .menu-items li:last-child a {
      display: inline-block !important;
      margin: 10px;
      padding: 15px 30px !important;
      padding-bottom: 10px !important;
      color: #192151 !important;
    }
    .menu-items li:not(:last-child) a:hover {
      color: #48c0b6 !important;
    }
    .header-menu.open button:hover {
      color: #fff !important;
      background: unset !important;
    }
    .hamburger:hover {
      background: unset !important;
      color: #Fff !important;
    }
    .hamburger:hover .bar {
      background-color: #fff;
    }
  }
  
  @media(max-width:767px){
    .header-logo img {
      max-width: 200px !important; 
    }
  }
 
  