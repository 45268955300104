.ourclientrow {
	padding: 40px 20px;
}
.ourclientrow p {
	text-align: center;
	font-size: 19px;
	color: #5e5f61;
	font-weight: 500;
}
.ourclientrow h1 {
	text-align: center;
	font-size: 45px;
	text-transform: uppercase;
	color: #192151;
    margin-bottom: 8px;
    line-height: 1.2;
}
.gallery1 {
	margin-top: 30px !important;
}
.casestudies {
	padding: 40px 20px;
}
.caseimg {
	text-align: center;
}
.casecontentbox h3 {
	font-size: 24px;
	color: #192151;
	font-weight: 500;
	margin: 15px 0 !important;
	max-width: 420px;
	line-height: 1.3em !important;
}
.caseimg img {
	margin-left: auto;
	margin-right: auto;
}
.caserowflex {
	display: flex;
	gap: 80px;
	align-items: center;
	max-width: 1170px;
	margin: auto;
	margin-top: 50px;
}
.casrrowodd.caserowflex div {
	flex: 1;
}
.casestudies p {
	text-align: center;
	font-size: 19px;
	color: #5e5f61;
	font-weight: 500;
}
.contactrow01 h2 {
	text-align: center;
	font-size: 45px;
	text-transform: uppercase;
	color: #192151;
	margin-bottom: 8px;
	line-height: 1.2;
}
.casedwnbtn button {
	font-size: 19px;
	font-weight: 500;
	color: #fff;
	background: #48c0b6;
	padding-left: 34px;
	padding-right: 34px;
}
.casedwnbtn button:hover {
	background: #48c0b6a6;
	color: #fff;
}
.page-id-03 .casedwnbtn {
	text-align: left;
}
.caseimg {
	flex: 1;
}
.casecontentbox {
	flex: 1;
}
.maxwid {
	max-width: 350px;
	margin: auto;
}
.rmaxwid {
	max-width: 350px;
}
@media(max-width:767px){
	.ourclientrow h1 {
		text-align: center;
		font-size: 26px;
	}
	.page-id-03 .casedwnbtn {
		text-align: center;
	}
	.casecontentbox .maxwid {
		text-align: center;
	}
	.casecontentbox .maxwid img{
		margin-left: auto;
		margin-right: auto;
	}
	.casecontentbox .rmaxwid {
		text-align: center;
	}
	.casecontentbox .rmaxwid img{
		margin-left: auto;
		margin-right: auto;
	}
	.caserowflex {
		margin-top: 35px;
	}
	.ourclientrow p {
		font-size: 17px;
	}
	.gallery1 {
		margin-top: 25px !important;
	}
	.casestudies h2 {
		text-align: center;
		font-size: 28px !important;
	}
	.casestudies p {
		font-size: 17px;
	}
	.casrrowodd {
		flex-direction: column;
		gap: 15px !important;
	}
	.casecontentbox h3 {
		font-size: 20px;
		max-width: 100% !important;
		margin-bottom: 0 !important;
	}
	.casrroweven {
		flex-direction: column-reverse;
		gap: 15px !important;
	}

}
@media(min-width:767px) and (max-width:1024px){
	.casecontentbox .casedwnbtn {
		text-align: left;
	}
	.casestudies h2 {
		text-align: center;
		font-size: 35px !important;
	}
	.ourclientrow h1 {
		text-align: center;
		font-size: 35px !important;
	}
	.casecontentbox h3 {
		font-size: 22px;
	}
	.customer .casecontentbox .maxwid {
		text-align: unset !important;
	}
}
@media(min-width:1025px) and (max-width:1200px){
	.ourclientrow h1 {
		text-align: center;
		font-size: 40px !important;
	}
	.casestudies h2 {
		text-align: center;
		font-size: 40px !important;
	}
}