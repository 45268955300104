.gallery {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }
  .gallery-item {
	border: 1px solid #adadad;
	border-radius: 2px;
	padding: 10px;
}
  .gallery-item img {
    width: 100%;
    height: auto;
    display: block;
  }
  .gallery-item img {
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width: 1200px) {
    .gallery {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  @media (max-width: 900px) {
    .gallery {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  @media (max-width: 600px) {
    .gallery {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 374px) {
    .gallery {
      grid-template-columns: 1fr;
    }
  }
  