.productfirstrow {
	padding: 40px 20px;
}
.productfirstrow img {
	margin-left: auto;
	margin-right: auto;
}
.productfirstrow h2 {
	color: #192151;
	font-size: 38px;
	text-align: center;
	text-transform: uppercase;
	margin-top: 20px !important;
}
.productfirstrow p {
	text-align: center;
	font-size: 23px;
	color: #4e4e4e;
	font-weight: 500;
	max-width: 900px;
	margin-left: auto;
	margin-right: auto;
}
.casedwnbtn {
	text-align: center;
}
.qtxdemo {
	margin: 30px 0 20px 0; 
}
@media(max-width:767px){
	.productfirstrow h2 {
		font-size: 24px;
	}
	.productfirstrow p {
		font-size: 18px;
	}
	.casedwnbtn button {
		font-size: 17px;
	}
}
@media(min-width:767px) and (max-width:1024px){
	.productfirstrow h2 {
		font-size: 28px;
	}
	.productfirstrow p {
		font-size: 19px;
	}
	.casedwnbtn button {
		font-size: 17px;
	}
}
@media(min-width:1025px) and (max-width:1200px){
	.productfirstrow h2 {
		font-size: 32px;
	}
	.productfirstrow p {
		font-size: 21px;
	}
	.casedwnbtn button {
		font-size: 17px;
	}
}