/* Breadcrumbs.css */
.breadcrumbs {
	max-width: 1450px;
	margin: 0 auto;
	background: #fff;
	padding: 20px;
}
.breadcrumbs li a {
	font-size: 20px;
	font-weight: 600;
	color: #192151;
	font-family: "Josefin Sans", sans-serif;
	line-height: 1.1;
}
.breadcrumbs li span {
	color: #6c6c6c;
	font-size: 20px;
}
.breadcrumbs li a:hover {
	color: #48c0b6;
    text-decoration: unset;
}
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 5px;
  }
  
  .breadcrumbs li {
    display: flex;
  }
  
  .breadcrumbs li:not(:last-child)::after {
    content: ' / ';
    padding: 0 5px;
  }
  
  .breadcrumbs a {
    text-decoration: none;
    color: #007bff;
  }
  
  .breadcrumbs a:hover {
    text-decoration: underline;
  }
  @media(max-width:1024px){
    .breadcrumbs li span {
      font-size: 18px;
    }
    .breadcrumbs li a {
      font-size: 18px;
    }
  }