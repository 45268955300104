.teampage h3 {
	font-size: 22px;
	margin-top: 20px !important;
	font-weight: 700;
	color: #192151;
}
.teampage .image {
	height: auto;
}
.teampage .trackninner {
	background-color: #ffffff;
	padding: 20px;
}