.footer {
	background: #192151;
	position: relative;
	padding: 40px 20px;
	overflow: hidden;
}
.footerinnercolumn {
	display: grid;
	grid-template-columns: repeat(4,1fr);
	gap: 10px;
	position: relative;
	z-index: 1;
}
.socialicon {
    margin-top: 15px;
}
.socialicon i {
	color: #fff;
	font-size: 24px;
}
.socialicon a:not(:last-child) {
	margin-right: 14px;
}
.fm h2 {
	text-align: left;
	font-size: 16px;
	color: #fff;
	text-transform: uppercase;
}
.foomenu {
	padding: 0;
	list-style: none;
	text-align: left;
	margin: 0;
}
.foomenu li {
	padding: 7px 0 !important;
}
.foomenu li a {
	color: #fff;
	text-decoration: unset !important;
	font-size: 16px;
}
.foomenu li {
	padding: 5px 0 !important;
}
.foomenu li a:hover {
	color: #48c0b6;
}
.socialicon i:hover {
	color: #48c0b6;
}
.footerinnercolumn4.fm p {
	font-size: 18px;
	text-align: left;
	color: #fff;
}
input:focus-visible {
	box-shadow: unset !important;
	border: 0 !important;
	outline: none !important;
}
.neweletterformbox {
	background: #fff;
	border-radius: 5px;
}
.neweletterformbox input {
	width: 100%;
	font-size: 18px;
	color: #192151;
	padding-right: 10px;
	border: 0 !important;
}
.neweletterformbox button {
	padding: 10px 10px;
	background: #49c0b6;
	font-size: 18px;
	font-weight: 700;
	padding-bottom: 6px !important;
	color: #fff;
	margin-top: 0 !important;
}
.neweletterformbox form {
	display: flex;
	justify-content: space-between;
	padding: 5px 10px;
	flex-direction: unset !important;
}
.neweletterformbox button:hover {
    background: #48c0b6a6 !important;
	color: #fff;
}
.foobtm p {
	color: #fff;
	margin-bottom: 0 !important;
	margin-top: 20px !important;
	font-size: 16px;
	font-weight: 400;
}
.footer::before {
	content: '';
	background: url(/src/img/footer01.png);
	width: 201px;
	height: 352px;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 0;
}
.footerinnercolumn1 img {
	max-width: 70%;
}
.footer::before {
	content: '';
	background: url(/src/img/footer01.png);
	width: 201px;
	height: 352px;
	position: absolute;
	left: 0;
	bottom: 0px !important;
	z-index: 0;
	opacity: 0.2;
}
.footer::after {
	content: '';
	background: url(/src/img/footer2.png);
	width: 227px;
	height: 480px;
	position: absolute;
	right: 0;
	bottom: 0;
	background-position: bottom;
	z-index: 0;
	opacity: 0.2;
}

  
  .scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #49c0b6;
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    cursor: pointer;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top:hover {
    background-color: #49c0b6;
  }
  
  .scroll-to-top:focus {
    outline: none;
  }
  
  html {
    scroll-behavior: smooth;
  }
  .foobtm p {
	text-align: center;
}
.footerinnercolumn4.fm p:last-child {
	margin-top: 10px !important;
}
/*
.footerinnercolumn1 {
	text-align: center;
} */
.footerinnerrow.maxwidcontainer {
	max-width: 1390px;
}
@media(max-width:1700px){
.footer::after {
	display:none !important;
}
.footer::before {
	display:none !important;
}		
}
@media(max-width:767px){
	.footerinnercolumn {
		display: grid;
		grid-template-columns: repeat(1,1fr);
		gap: 10px;
		position: relative;
		z-index: 1;
	}
	.footerinnercolumn1 img {
		max-width: 160px;
	}
	.foomenu {
		column-count: 2;
	}
	.fm {
		margin-top: 15px;
	}
	.foobtm {
		margin-top: 30px !important;
	}
}
@media(min-width:768px) and (max-width:1024px){
	.footerinnercolumn1 img {
		max-width: 200px !important;
	}
	.footerinnercolumn2.fm {
		padding-left: 20px;
	}
}
@media(max-width:1024px){
	.fm p {
		font-size: 16px !important;
	}
}